import { select2ify } from './utils/select2ify';

select2ify($('.select2'));

// wandelt z.B. 14011996 in 14.01.1996 um
const $stringToDate = $('.stringtodate');
if ($stringToDate != null && $stringToDate.length > 0) {
  $stringToDate.stringToDate();
}

for (const backButton of document.querySelectorAll('.js-back-button')) {
  backButton.addEventListener('click', window.history.back.bind(window.history));
}
