import { language } from './select2-de';

/**
 * Hilfsfunktion für Select2, damit dieses die korrekte Breite hat und deutsch ist.
 */
export function select2ify(el, userOptions) {
  const options = {
    //Select2 auf Deutsch umstellen:
    language: language,
    //Resposive design
    width: 'resolve',
    placeholder: el.attr('placeholder')
  };

  $.extend(true, options, userOptions);

  return el.select2(options).removeClass('form-control');
}
