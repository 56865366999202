$('[rel=popover]').popover({
  container: 'body',
  placement: 'auto'
});
$('[title]:not([rel=popover])').tooltip();

$('.datepicker').datepicker({
  startDate: new Date(1990, 1, 1),
  language: 'de',
  autoclose: true,
  forceParse: false,
  startView: 'days',
});

/**
 * Tooltips für Verkleinerung von Button-Text
 */

if (window.matchMedia) {
  const mq = window.matchMedia('(max-width: 1199px)');
  mq.addListener(buttonTextResponsive);
  buttonTextResponsive(mq);
}
function buttonTextResponsive(mq) {

  if (mq.matches) {
    $('.button-text').each(function () {
      iterateButton($(this), true);
    });

  } else {
    $('.button-text').each(function () {
      iterateButton($(this), false);
    });

  }
}

function iterateButton(item, matched) {
  const text = item.text();
  let $btn = item.parents('.btn');

  if ($btn.length < 1) {
    $btn = item.parents('a');
  }

  if (matched) {
    if (typeof $btn.attr('title') === 'undefined') {
      $btn.attr('title', text);
      $btn.tooltip();
    }
  } else {
    if ($btn.attr('data-original-title') === text) {
      $btn.removeAttr('title');
      $btn.removeAttr('data-original-title');
      $btn.tooltip('destroy');
    }
  }
}
